<template>
  <div class="container">
    <PublicHeader :totalName="title" />
    <div class="title" v-if="Info.Type !== 'WhitePaper'">{{ Info.Ttile }}</div>
    <div class="time" v-if="Info.Type !== 'WhitePaper'">{{ Info.ShowCreateTime | getLocalDate }}</div>
    <div class="content" v-html="Info.Content" ></div>
  </div>
</template>

<script>
import PublicHeader from '@/components/publicHeader'
import systemApi from '@/api/system'
export default {
  components: {
    PublicHeader
  },
  data() {
    return {
      Info: {},
      title: ''
    }
  },
  async mounted() {
    if (this.$route.query.id) {
      var res = await systemApi.getContentDetail({ contentId: this.$route.query.id })
      this.Info = res.Data
      if (this.Info.Type == 'WhitePaper')
        this.title = this.Info.Ttile
      else
        this.title = this.$t('news').title
    }
  }
}
</script>

<style scoped>
.container {
  padding-top: 60px;
}

.title {
  padding: 10px 13px;
  font-weight: bold;
  font-size: 20px;
  color: #333333;
  line-height: 30px;
}

.time {
  padding: 0 13px;
  font-size: 12px;
  color: #999999;
}

.content {
  margin: 13px;
  font-size: 15px;
  color: #333333;
  line-height: 25px;
  white-space: pre-wrap;
}
</style>
